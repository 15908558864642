import React from 'react';
import { useHistory } from 'react-router-dom'; 
import { useSelector } from 'react-redux';
import { selectShipmentDetail } from '../core/redux/selectors';
import { SHIPMENT_DETAIL } from '../core/constants/ROUTES';
import { PickupOptionsButtons, DownloadCenterBtns } from './';

export default () => {
    const history = useHistory();
    const shipment = useSelector(selectShipmentDetail);

    const handleGo = (id) => {
        history.push({
            pathname: SHIPMENT_DETAIL,
            search: `?id=${id}`,
            state: { id }
        })
    }

    return (
        <div className="shipment-info-bar">
            <PickupOptionsButtons
                id={shipment.idd}
                shipment={shipment}
                onReload={() => handleGo(shipment.ship_code)}
            />
            <DownloadCenterBtns shipment={shipment} icons />
        </div>
    );
}