import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { PickupOptionsButtons, DownloadCenterBtns } from '../';
import { Link, Status } from '../controls';
import { setShipmentSeleted, setShipmentsReload, setShipmentsSearching } from '../../core/redux/actions';
import { getShipmentStatus } from '../../core/constants/ShipmentsStatus';
import { selectShipmentSeleted } from '../../core/redux/selectors';
import { useTranslate } from '../../core/contexts';
import { POWRIcon } from '../styledComponents';
import { COLOR } from '../../core/constants/Colors';

export default ({ mobile }) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const shipment = useSelector(selectShipmentSeleted);
    const { idd, ship_code, rmas, tns } = shipment;
    const [ showAll, setShowAll ] = useState(false);
    const dateFormat = sessionStorage.getItem('dateFormat');
    const timeFormat = sessionStorage.getItem('timeFormat');

    const createdDate = (date) => {
        return date && moment(`${date}`, 'YYYYMMDDHHmmss').format(dateFormat) !== 'Invalid date' ?
            moment(`${date}`, 'YYYYMMDDHHmmss').format(dateFormat) : '—';
    }
    const status = getShipmentStatus(shipment.status, shipment.ship_event);
    const carrier_msg = `${shipment.carrier_msg && shipment.carrier_msg.split(', desc=')[1] ? `(${shipment.carrier_msg.split(', desc=')[0]})` : ''} ${shipment.courier_dispatch_error || ''}`

    return (
        <div className="shipment-info-bar">
            {!mobile && <div className="d-flex justify-content-end mb-2" >
                <div onClick={() => dispatch(setShipmentSeleted({}))}>
                    <POWRIcon className="fas fa-times" color={COLOR.DARK_GREY_1} size="16px" selectable />
                </div>
            </div>}
            {!mobile ? 
                <div className="main-info">
                    <div className="row-info">
                        <label>{translate('gridColumns.shipmentID')}:</label>
                        <div className=" d-flex">
                            <h4 className="mb-0">{ship_code || idd}</h4>
                            <Status className="ml-2 justify-content-end" status={status} />
                        </div>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.rmas')}:</label>
                        <h4>{rmas ? rmas.length > 1 ? rmas.join(', ') : rmas[0] : '—'}</h4>
                    </div>
                    <div className="row-info">
                        <label>{translate('formLabel.tracking')}:</label>
                        <h4>{tns ? tns.length > 1 ? tns.join(', ') : tns[0] : '—'}</h4>
                    </div>
                    {showAll && <Fragment>
                        <div className="row-info">
                            <label>{translate('formLabel.date')}</label>
                            <p>{shipment.ship_event_time ?
                                `${moment(`${shipment.ship_event_time}`, 'YYYYMMDDHHmmss').format(`${dateFormat} ${timeFormat}`)}` : '—'}</p>
                        </div>
                        <div className="row-info">
                            <label>{translate('gridColumns.agreedPickupDate')}:</label>
                            <p>{shipment.agreed_pickup_dt ? 
                                `${shipment.agreed_pickup_dt ? moment(`${shipment.agreed_pickup_dt}`, 'MM/DD/YYYY').format(`${dateFormat}`) : '—'} ${shipment.pickup_schedule_time || ''}` : '—'
                            }</p>
                        </div>
                        <div className="row-info">
                            <label>{translate('message.courierDispatch')}:</label>
                            <p>{shipment.confirmation_number || '—'}</p>
                        </div>
                        <div className="row-info">
                            <label>{translate('formLabel.carrier')}:</label>
                            <p>{shipment.carrier_msg ? shipment.carrier_msg.split(', desc=')[1] ? shipment.carrier_msg.split('desc=')[1] : shipment.carrier_msg : '—'}</p>
                        </div>
                        {carrier_msg.trim() !== "" && <label className="carrier-msg">{carrier_msg}</label>}
                    </Fragment>}
                    <div className="d-flex justify-content-end" >
                        <Link onClick={() => setShowAll(!showAll)}>
                            {showAll ? translate('button.showLessDetails') : translate('button.showAllDetails')}
                        </Link>
                    </div>
                </div>
            :
                <div className="main-info">
                    <div className="row-info">
                        <label>{translate('gridColumns.shipmentID')}:</label>
                        <h4 className="mb-0">{ship_code || idd}</h4>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.created')}:</label>
                        <p>{createdDate(shipment.date_created)}</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.createdBy')}:</label>
                        <p>{shipment.principal}</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.carrier')}:</label>
                        <p>{shipment.carrier_msg ? shipment.carrier_msg.split(', desc=')[1] ? shipment.carrier_msg.split('desc=')[1] : shipment.carrier_msg : '—'}</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('formLabel.carrier')}:</label>
                        <p>{carrier_msg.trim() !== "" && <label className="carrier-msg">{carrier_msg}</label>}  </p>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.rmas')}:</label>
                        <h4>{rmas ? rmas.length > 1 ? rmas.join(', ') : rmas[0] : '—'}</h4>
                    </div>
                    <div className="row-info">
                        <label>{translate('formLabel.tracking')}:</label>
                        <p>{tns ? tns.length > 1 ? tns.join(', ') : tns[0] : '—'}</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.agreedPickupDate')}:</label>
                        <p>{shipment.agreed_pickup_dt ? 
                            `${shipment.agreed_pickup_dt ? moment(`${shipment.agreed_pickup_dt}`, 'MM/DD/YYYY').format(`${dateFormat}`) : '—'} ${shipment.pickup_schedule_time || ''}` : '—'
                        }</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('message.courierDispatch')}:</label>
                        <p>{shipment.confirmation_number || '—'}</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.status')}:</label>
                        <Status status={status} />
                    </div>
                </div>
        
            }
            <PickupOptionsButtons
                id={idd}
                shipment={shipment}
                onReload={(type) => {
                    dispatch(setShipmentsReload({type, shipment}))
                    dispatch(setShipmentsSearching(true))
                }}
            />
            <DownloadCenterBtns shipment={shipment} />
        </div>
    );
}
