import React, { useState, useContext, Fragment } from "react";
import { useHistory, withRouter } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Icon from "../../controls/icon";
import { AppWrapperContext } from "../../AppShell/AppShell.react";
import { useTranslate } from "../../../core/contexts";
import { useDispatch, useSelector } from "react-redux";
import { clickOutsideReturn, resetReturnFlow, setDownloadParams, setShipments, setShipmentSeleted, setShipmentsProp } from "../../../core/redux/actions";
import { selectReturnFlowCurrentStep, selectUserInfo } from "../../../core/redux/selectors";
import { LoggingOutModal } from "../../modals";
import { RETURN, RLSP } from "../../../core/constants/ROUTES";

const showDescription = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const LinkDescription = styled.div`
  opacity: 0;
  display: none;
  position: fixed;
  left: 100%;
  top: 0;
  background-color: #fff;
  margin: 8px;
  padding: 8px;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 #00000010;
  border: 1px #e6e6e6 solid;

  max-width: 230px;

  > h6 {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #9194a7;
    line-height: 12px;
  }

  > hr {
    margin: 8px -8px;
  }

  > p {
    margin: 0;
    padding: 0;
    min-height: 0;
    font-size: 10px;
    line-height: 12px;
    color: #9194a7;
    font-weight: 100;
  }
`;

const LinkIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: inherit;
  flex-shrink: 0;
`;

const LinkText = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  flex-grow: 1;
  color: #ffffff;
  font-family: "CiscoSansTT-Regular", sans-serif;
  font-size: 14px;
  transition: color 0.3s, opacity 0.3s;
`;

const LinkWrapper = styled.li`
  position: relative;

  ${LinkText} {
    opacity: ${(props) => (props.sidePanelOpen ? "1" : "0")};
  }

  &:hover {
    ${LinkDescription} {
      display: ${(props) => (props.sidePanelOpen ? "none" : "block")};
      animation: ${(props) => (props.sidePanelOpen ? "unset" : showDescription)}
        0.3s 0.65s ease forwards;
    }
  }
`;

const Link = styled.a`
  overflow: hidden;
  display: flex;
  height: 56px;
  align-items: center;
  transition: background-color 0.3s;
  color: ${(props) => (props.$isActive ? "#ffffff" : "#c5d8df")};

  cursor: pointer;

  &::before {
    position: absolute;
    content: "";
    width: ${(props) => (props.$isActive ? "3px" : "0")};
    height: inherit;
    background-color: white;
    left: 0px;
    top: 0px;
  }

  ${LinkText} {
    color: ${(props) => (props.$isActive ? "#ffffff" : "#c5d8df")};
  }

  ${LinkIcon} {
    svg {
      color: ${(props) => (props.$isActive ? "#ffffff" : "#c5d8df")} !important;
    }
  }

  &:hover {
    background-color: #00000020;
    text-decoration: none;
  }
`;

const LinkAnchor = styled.a`
  overflow: hidden;
  display: flex;
  height: 56px;
  align-items: center;
  transition: background-color 0.3s;
  color: ${(props) => (props.$isActive ? "#ffffff" : "#c5d8df")};

  cursor: pointer;

  &::before {
    position: absolute;
    content: "";
    width: ${(props) => (props.$isActive ? "3px" : "0")};
    height: inherit;
    background-color: white;
  }

  ${LinkText} {
    color: ${(props) => (props.$isActive ? "#ffffff" : "#c5d8df")};
  }

  ${LinkIcon} {
    svg {
      color: ${(props) => (props.$isActive ? "#ffffff" : "#c5d8df")} !important;
    }
  }

  &:hover {
    background-color: #00000020;
    text-decoration: none;
  }
`;

const NavLink = ({ id, description, to, icon, isActive, shouldOpenWindow }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isSidePanelOpen, toggleSidePanel } = useContext(AppWrapperContext);
  const { translate } = useTranslate();
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [loggingOut, setLoggingOut] = useState(false);
  const currentStep = useSelector(selectReturnFlowCurrentStep);
  const userInfo = useSelector(selectUserInfo);

  const updatePosition = ($e) => {
    const { top, right } = $e.target.getBoundingClientRect();
    setPosition({ top, left: right });
  };

  const clickOptionMenu = () => {
    if (to === 'logout') setLoggingOut(true);
    else if (currentStep === 5 && userInfo.rmaLogin) {
      history.push(to);
      dispatch(resetReturnFlow());
    } else if (history.location.pathname === RETURN && currentStep !== 5) {
      dispatch(clickOutsideReturn(to));
      
    }
    //Add this to handle behavior for rlsp unsaved information and move to different page  
    else if (history.location.pathname === RLSP) {
     dispatch(clickOutsideReturn(to)); 
    }
   
    else{
      // if(history.location.pathname === DOWNLOAD) 
      history.push(to);
      dispatch(setShipmentSeleted({}));
      dispatch(setDownloadParams(null));
      dispatch(setShipments([]));
      dispatch(setShipmentsProp('rmaSearchText',''));
    }
  }

  return (
    <Fragment>
      <LinkWrapper onMouseEnter={updatePosition} sidePanelOpen={isSidePanelOpen}>
        {
          shouldOpenWindow ?
            <LinkAnchor target="_blank" href={to} $isActive={isActive} onClick={
              () => to === 'logout' ? setLoggingOut(true) : toggleSidePanel(false)
            }>
              <LinkIcon>
                <Icon
                  content={icon}
                  height="20px"
                  width="20px"
                  type={isActive ? "solid" : ""}
                ></Icon>
              </LinkIcon>
              <LinkText>{translate(id)}</LinkText>
            </LinkAnchor>
            :
            <Link $isActive={isActive} onClick={() => { toggleSidePanel(false); clickOptionMenu() }}>
              <LinkIcon>
                <Icon
                  content={icon}
                  height="20px"
                  width="20px"
                  type={isActive ? "solid" : ""}
                ></Icon>
              </LinkIcon>

              <LinkText>{translate(id)}</LinkText>
            </Link>
        }

        <LinkDescription
          className="d-none d-lg-block"
          style={{
            left: position.left,
            top: description ? position.top : position.top + 6,
          }}
        >
          <h6>{translate(id)}</h6>
          {description && (
            <Fragment>
              <hr />
              <p>{translate(description)}</p>
            </Fragment>
          )}
        </LinkDescription>
      </LinkWrapper>
      {loggingOut && <LoggingOutModal show={true} onClose={() => setLoggingOut(false)} />}
    </Fragment>
  );
};

export const NavigationLink = withRouter(({ location, ...props }) => {
  // IsActive Functionality
  const isActive = location.pathname === props.to;

  return <NavLink {...props} isActive={isActive} />;
});
