import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RMAList, RMASearchBar } from '../../components';
import { getRMA, saveRMA, getContactInfo, getValidAvailableDCRMA } from '../../services';
import { useTranslate } from '../../core/contexts';
import { useDispatch, useSelector } from 'react-redux';
import { resetReturnFlow, resetRMAInfo, setRMASearching, setRMASearchResults } from '../../core/redux/actions';
import { selectRMASearchParameters, selectRMASearching, selectRMASearchResults } from '../../core/redux/selectors';
import { FullPage } from '../../components/AppShell/partials/FullPage.react';
import { IndiaRMAModal, LabelOnlyModal } from '../../components/modals';
import { DOCUMENTATION, RETURN, SEARCH_RMA } from '../../core/constants/ROUTES';
import { CONFIG_PARAMS, COUNTRY } from '../../core/constants';
import { STORAGE } from '../../core/constants';
import { POWRButton } from '../../components/styledComponents';
import { useAlert, useGlobalMessage } from '../../core/hooks';
import { Icon, Link } from '../../components/controls';
import { scrollTop } from '../../core/helpers';

export default props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const routeParams = useParams();
    const { translate } = useTranslate();
    const { showAlert, removeAlerts } = useAlert();
    const { showPageMessage } = useGlobalMessage();

    const parameters = useSelector(selectRMASearchParameters);
    const searching = useSelector(selectRMASearching);
    const data = useSelector(selectRMASearchResults);

    const [checks, setChecks] = useState([]);
    const [paramsSaved, setParamsSaved] = useState(null);
    const [showIndiaModel, setShowIndiaModel] = useState(false);
    const [showLabelOnlyModal, setShowLabelOnlyModal] = useState(false);
    const [DCGenerated, setDCGenerated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        clearSessionStorage();
        setSearching(true);

        let params = {
            module: 'GCT_Step1',
            country: '',
            rmaCategory: ''
        }
        showPageMessage(params, '/rmas');
        validateDCGenerated();
    }, []);

    useEffect(() => {
        setSearching(true);
        searchShipments();
    }, [parameters]);

    const setSearching = (v) => {
        dispatch(setRMASearching(v))
    }

    const validateDCGenerated = () => {
        let ssConfigParams = sessionStorage.getItem(STORAGE.CONFIG_PARAMS);
        let config_params = ssConfigParams ? JSON.parse(ssConfigParams.replace(/\s+/g, "")) : null;
        if (config_params && config_params.dtos) {
            let DC_Generated = config_params.dtos.find(x => x.name === CONFIG_PARAMS.DC_GENERATED);
            setDCGenerated(DC_Generated.value === "true" ? true : false);
        }
    }

    const searchShipments = () => {
        if (parameters && searching) {
            getRMA(parameters).then(response => {
                let resStr = response.res ? typeof (response.res) === 'string' ?
                    response.res.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                    : response.res : { dtos: [] };
                let dtos = typeof (resStr) === 'string' ? JSON.parse(resStr).dtos : resStr.dtos;
                setSearching(false);
                dispatch(setRMASearchResults(dtos));
                if (dtos.length > 0) handleAlert(dtos.length);

            }).catch(err => {
                console.log(err);
                setSearching(false);
            });
        }
    }

    const clearSessionStorage = () => {
        sessionStorage.removeItem(STORAGE.SEARCH_NAME)
        sessionStorage.removeItem(STORAGE.SAVE_NAME)
        sessionStorage.removeItem(STORAGE.CONTACT_INFO)
        sessionStorage.removeItem(STORAGE.LABEL_ONLY)
        sessionStorage.removeItem(STORAGE.RESUME_PICKUP)
        sessionStorage.removeItem(STORAGE.RESUME_SHIPMENT)
    };

    const handleNext = () => {
        if (isLoading) return;
        setIsLoading(true);
        const isFA = routeParams.type === 'FA';
        const body = {
            param_rma_or_tradein: checks.join(', '),
            param_fa_case_num: !isFA ? "" : data.filter(x => checks.find(y => y === x.rma_num)).map(x => x.fa_case_num).join(','),
            isSimpleSearch: true,
            isReturnFa: isFA
        };

        getRMA(body).then(response => {
            let dtos = response.res.dtos;
            let country = dtos[0].country[0];
            const rmas = checks;
            if (dtos.length > 0) {
                const paramsSave = {
                    ship_id: 0,
                    rmas: rmas.length > 1 ? rmas.sort((a, b) => a > b) : [rmas[0]],
                    simple: false
                };

                sessionStorage.setItem(STORAGE.SEARCH_NAME, JSON.stringify(dtos))
                if (country === COUNTRY.INDIA && DCGenerated) {
                    validateDeliveryChallan(paramsSave);
                } else if (!isFA && (country === COUNTRY.US || country === COUNTRY.CANADA)) {
                    setParamsSaved(paramsSave);
                    setShowLabelOnlyModal(!showLabelOnlyModal);
                    setIsLoading(false);
                } else {
                    handleSaveRMA(paramsSave);
                }
            } else {
                clearSessionStorage();
                setIsLoading(false);
                alert('RMA ' + checks[0] + translate('message.wasNotFound'))
            }
        }).catch(error => {
            clearSessionStorage();
            setIsLoading(false);
            alert('RMA ' + checks[0] + translate('message.wasNotFound'))
        });
        removeAlerts();
    }

    const validateDeliveryChallan = (paramsSave) => {
        getValidAvailableDCRMA({ rmas: paramsSave.rmas }).then(response => {
            let data_response = response.res.dtos;

            if (data_response.length === 0) {
                setParamsSaved(paramsSave);
                setShowIndiaModel(!showIndiaModel);
                setIsLoading(false);
            } else {
                if (typeof paramsSave.rmas === "object") {
                    paramsSave.rmas.map((rmaSave) => {
                        if ((data_response.filter(x => x.rma_num === rmaSave && x.dc_generated))) {
                            handleSaveRMA(paramsSave);
                        } else {
                            setParamsSaved(paramsSave);
                            setShowIndiaModel(!showIndiaModel);
                            setIsLoading(false);
                        }
                    });
                } else {
                    if (data_response && data_response[0] && data_response[0].dc_generated) {
                        handleSaveRMA(paramsSave);
                    } else {
                        setParamsSaved(paramsSave);
                        setShowIndiaModel(!showIndiaModel);
                        setIsLoading(false);
                    }
                }
            }
        });
    }

    const handleAlert = (count) => {
        if (count >= 200)
            showAlert({
                id: 'returnedMore200RMA',
                message: translate('message.returnedMore200RMA'),
                page: SEARCH_RMA
            });

        else if (count >= 100)
            showAlert({
                id: 'returnedMore100RMA',
                message: translate('message.returnedMore100RMA'),
                page: SEARCH_RMA
            });
        else
            showAlert({
                id: 'returnedMore100RMA',
                message: `${translate('message.searchReturned')} ${count} ${translate('title.rmas')}`,
                page: SEARCH_RMA
            });
    }

    const handleSaveRMA = (params) => {
        if (isSaving) return;
        setIsSaving(true);
        saveRMA(params).then(response => {
            if (response.res.success) {
                let dtosSave = response.res.dtos;
                const paramsCInfo = {
                    //_dc: 1601322508190,//TODO we dont know where this value came from yet
                    ship_id: dtosSave[0].idd
                };
                sessionStorage.setItem(STORAGE.SAVE_NAME, JSON.stringify(dtosSave.map(x => ({ ...x, isFA: routeParams.type === 'FA' }))));
                getContactInfo(paramsCInfo).then(contact => {
                    if (contact.res.success) {
                        let dtosCInfo = contact.res.dtos;
                        sessionStorage.setItem(STORAGE.CONTACT_INFO, JSON.stringify(dtosCInfo));
                        dispatch(resetRMAInfo());
                        dispatch(resetReturnFlow());
                        scrollTop();
                        setIsLoading(false);
                        setIsSaving(false);
                        history.push(RETURN);
                    } else {
                        clearSessionStorage();
                        setIsLoading(false);
                        setIsSaving(false);
                    }
                }).catch(error => {
                    clearSessionStorage();
                    setIsLoading(false);
                    setIsSaving(false);
                    console.log(error);
                });
            } else {
                clearSessionStorage();
                setIsLoading(false);
                setIsSaving(false);
            }
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
            setIsSaving(false);
        });
    }

    const createDocumentationIndia = () => {
        history.push({
            pathname: DOCUMENTATION,
            state: { rma: checks[0] }
        })
    }

    const handleLabelOnly = (params, labelOnly) => {
        sessionStorage.setItem(STORAGE.LABEL_ONLY, JSON.stringify(labelOnly));
        handleSaveRMA(params);
    }

    const clearCheck = (val) => {
        let newChecks = checks.filter((x) => x !== val);
        setChecks(newChecks);
    }

    const titlePage = <h1 className="m-0">{translate(`title.${routeParams.type === 'FA' ? 'fa' : 'rma'}Search`)}</h1>;

    const selectedChecks = (
        <div className="d-flex align-items-center flex-wrap px-3">
            {checks.length > 0 &&
                <Link className="clearAllBtn" onClick={() => setChecks([])} >
                    {translate('button.clearAll')}
                </Link>
            }
            {checks.map((item, i) => (
                <div className="rmas-cheked d-flex align-items-center my-1 ml-2 px-2" key={`rma-checked-${i}`}>
                    <label>{item}</label>
                    <div className="clearBtn ml-1" onClick={() => clearCheck(item)} >
                        <Icon content="times" color="#099AD6" height="14px" />
                    </div>
                </div>
            ))}
        </div>
    );

    const nextButton = (
        <POWRButton
            disabled={searching || data.length < 1 || checks.length < 1}
            onClick={handleNext}
        >
            {translate('button.next')}
        </POWRButton>
    );

    return (
        <FullPage>
            <div className="d-flex d-xl-none">
                <div className="col-12 pb-16">
                    {titlePage}
                </div>
            </div>
            <div className="col-12 col-xl-3 p-xl-0">
                <RMASearchBar location={props.location} />
            </div>
            <div className="col-12 col-xl-9 p-xl-0">
                <div className="main-rmas">
                    <div className="d-none d-xl-flex row-between rmas-header">
                        {titlePage}
                        <div className="d-flex align-items-center">
                            {selectedChecks}
                            {nextButton}
                        </div>
                    </div>
                    <div className="pt-2 pt-xl-0">
                        {searching ?
                            <p>{translate('aux.loading')}</p>
                            :
                            <RMAList
                                isFA={routeParams.type === 'FA'}
                                full={true}
                                items={data}
                                singleSelect={false}
                                checks={checks}
                                setChecks={setChecks}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className="d-xl-none">
                <div className="col-12 pt-2">
                    {selectedChecks}
                </div>
                <div className="col-12 pt-2 rmas-sm">
                    {nextButton}
                </div>
            </div>
            <LabelOnlyModal
                show={showLabelOnlyModal}
                onClose={() => setShowLabelOnlyModal(!showLabelOnlyModal)}
                onContinue={handleLabelOnly.bind(this, paramsSaved)}
            />
            <IndiaRMAModal
                proceedWODocument={handleSaveRMA.bind(this, paramsSaved)}
                createDocument={createDocumentationIndia.bind(this)}
                show={showIndiaModel}
                close={() => setShowIndiaModel(!showIndiaModel)}
            />
        </FullPage>
    );
}
