import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AppWrapperContext } from "../../AppShell/AppShell.react";
import { NavigationLink } from "./NavigationLink.react";
import { MAIN_NAVIGATION } from "../../../core/constants/Navigation";
import { useSelector } from "react-redux";
import { selectUserInfo, selectBaseRMAInfo } from "../../../core/redux/selectors";
import { ROLES, STORAGE } from "../../../core/constants";

const NavigationContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  overflow-y: hidden;
  overflow-x: hidden;
`;

const NavigationWrapper = styled.nav`
  display: flex;
  flex-direction: column;

  box-shadow: 0 0 4px 0 #dfdfdf;

  transition: width 0.3s;

  margin-top: -64px;
  padding-top: 64px;

  overflow-y: hidden;

  background-color: #004766;
  width: ${(props) => (props.sidePanelOpen ? "352px" : "80px")};
  height: 100%;

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.xs}) {
    width: ${(props) => (props.sidePanelOpen ? "352px" : "0")};
  }
`;

export const Navigation = () => {
  const { isSidePanelOpen } = useContext(AppWrapperContext);
  const userInfo = useSelector(selectUserInfo);
  const rma = useSelector(selectBaseRMAInfo);
  const [menuOptions, setMenuOptions] = useState(MAIN_NAVIGATION.map(x => ({ ...x, disabled: false })));

  const getToggleConfigParam = (name, configP) => { 
    let config = configP?.dtos && configP.dtos.find(x => x.name === name);
    return (config && config.value) && config.value === "true";
  }

  useEffect(() => {
    const configParams = sessionStorage.getItem(STORAGE.CONFIG_PARAMS);
    let configP = configParams ? JSON.parse(configParams.replace(/\s+/g, "")) : null;
    let item = configP?.dtos && configP.dtos.find(x => x.name === "RLSP.powr3.enabled");
    let newRLSPEnabled = item && item.value ? item.value : '';
    let isRTFAvailable = getToggleConfigParam("create.rtf.commercial.invoice", configP);
    let isDocsCIAvailable = getToggleConfigParam("ch.and.fr.commercial.docs", configP);

    if (userInfo) {
      let newMenuOptions = menuOptions;

      if (newRLSPEnabled && newRLSPEnabled !== 'true') {
        newMenuOptions = newMenuOptions.map(x => x.id === 'title.rlspEditor' ? {
          ...x, to: `${process.env.REACT_APP_ADMIN_APIURL}/carrier_editor.jsp`,
          shouldOpenWindow: true
        } : x);
      }

      if (userInfo.rmaLogin) {
        newMenuOptions = newMenuOptions.map(x => ({ ...x, disabled: true }));
        newMenuOptions = newMenuOptions.map(x =>
          x.id === 'title.homeMenu' || x.id === 'title.shipmentSearch' || x.id === 'title.documentationAccessindiaOnly'
            ? { ...x, disabled: false } : x);
      }
      else {
        if (userInfo.role === ROLES.RLSP)
          newMenuOptions = newMenuOptions.map(x => x.id !== 'title.rlspEditor' && x.id !== 'title.shipmentSearch' ? { ...x, disabled: true } : x);
        else {
          if (!(userInfo.role === ROLES.ADMIN || userInfo.role === ROLES.AR_AGENT)) 
            newMenuOptions = newMenuOptions.map(x => x.id === 'title.rlspEditor' ? { ...x, disabled: true } : x);
          if (((userInfo.role === ROLES.ADMIN || userInfo.role === ROLES.AR_AGENT) && !isDocsCIAvailable))
            newMenuOptions = newMenuOptions.map(x => x.id === 'title.documentReports' ? { ...x, disabled: true } : x);
          if (!userInfo.factReturnsAllowed)
            newMenuOptions = newMenuOptions.map(x => x.id === 'title.factReturns' ? { ...x, disabled: true } : x);
          if (!userInfo.documentAccessAllowed)
            newMenuOptions = newMenuOptions.map(x => x.id === 'title.documentationAccessindiaOnly' ? { ...x, disabled: true } : x);
          if (!userInfo.waybillAllowed)
            newMenuOptions = newMenuOptions.map(x => x.id === 'title.preprintedWaybillusCanadaOnly' ? { ...x, disabled: true } : x);
          if (!isRTFAvailable)
            newMenuOptions = newMenuOptions.map(x => x.id === 'title.RTFCI' ? { ...x, disabled: true } : x);  
        }
      }
      setMenuOptions(newMenuOptions);
    }
  }, [userInfo, rma]);

  return (
    <NavigationWrapper sidePanelOpen={isSidePanelOpen}>
      <NavigationContainer>
        {menuOptions.filter(x => !x.disabled).map((props) => (
          <NavigationLink key={props.id} {...props} />
        ))}
      </NavigationContainer>
    </NavigationWrapper>
  );
};
