import React, { useEffect, useState, Fragment } from "react";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { AppShell } from "./components/AppShell/AppShell.react";
import { addSecurity, gtagService, validateBillingAddress } from './services';
import {
  AddressBook, Documentation, Home, PreprintedWaybill, Return, RMAs, ShipmentDetail, Shipments, Schedule, DownloadCenter, Settings, RLSPPage, RTFCommercialInvoice,
  DocumentReports,
  MenuReports
} from "./pages";
import {
  BASE, DASHBOARD, RETURN, SEARCH_SHIPMENTS, SEARCH_RMA, SEARCH_PREPRINTED_WAYBILL, DOCUMENTATION, DOWNLOAD, SHIPMENT_DETAIL, SCHEDULE, ADDRESS_BOOK, SETTINGS, RLSP,
  RTFCOMMERCIALINVOICE, FR_PACKING_LIST, CH_COMMERCIAL_INVOICE, MENU_REPORTS
} from './core/constants/ROUTES';
import "./styles/main.scss";
import { useAuth } from "./core/hooks";
import { useTranslate } from './core/contexts';
import { LOGINTYPE_RMA_EMAIL, ROLES, PING_TIME, STORAGE } from './core/constants';
import { selectUserInfo } from "./core/redux/selectors";
import 'moment/min/locales';
import { ping } from "./services/loginServices";
import rlsp from "./pages/rlsp";
import { BASE_URL_API } from "./core/constants/URLS";

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { authUser, handleSecurityData } = useAuth();
  const { dispatchI18n } = useTranslate();

  const [login, setLogin] = useState(null);
  const [loginType, setLoginType] = useState(null);
  const [isRTFMenuAvailable, setIsRTFMenuAvailable] = useState(false);
  const [isreportsMenuAvalable, setIsReportsMenuAvailable] = useState(false);

  const userInfo = useSelector(selectUserInfo);

  useEffect(() => {
    setInterval(() => {
      ping()
    }, PING_TIME);
  }, []);

  useEffect(() => {
    changeLang();
    const security = JSON.parse(sessionStorage.getItem('securityData'));
    setRTFMenuConfig();
    if (security && moment().isBefore(security.expiresAt)) {
      handleSecurityData(security);
      setLogin(security);
      setLoginType(security.login_type);
      let loginTypeNames = ['Unknown', 'RMA&Email', 'CiscoAccount', 'CiscoMobile', 'CiscoIReturns'];
      gtagService.event('Login', 'SuccessLogin', loginTypeNames[security.login_type]);
    }
    else signIn();
  }, [dispatch]);

  useEffect(() => {
    gtagService.pageView(window.location.href);
  }, [location]);

  const signIn = async () => {
    if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'uat3') {
      const body = { type: 'cisco', login: 'asptest' };
      await addSecurity(body)
    }

    const security = await authUser(); 
    if (security) {
      setRTFMenuConfig();
      setLogin(security);
      setLoginType(security.login_type);
    }else{
      window.location = BASE_URL_API + '/ciscologin/';
    }

  };

  const setRTFMenuConfig = () => {
    const configParams = sessionStorage.getItem(STORAGE.CONFIG_PARAMS);
    let configP = configParams ? JSON.parse(configParams.replace(/\s+/g, "")) : null;
    let rtfAvailableConfig = configP?.dtos && configP.dtos.find(x => x.name === "create.rtf.commercial.invoice");
    let reportsMenuAvailableConfig = configP?.dtos && configP.dtos.find(x => x.name === "ch.and.fr.commercial.docs"); 
    setIsRTFMenuAvailable((rtfAvailableConfig && rtfAvailableConfig.value) && rtfAvailableConfig.value === "true");
    setIsReportsMenuAvailable((reportsMenuAvailableConfig && reportsMenuAvailableConfig.value) && reportsMenuAvailableConfig.value === "true");
  }

  const changeLang = () => {
    let ln = localStorage.getItem('powr3_lang_code');
    if (ln) dispatchI18n({ type: 'setLanguage', payload: ln });
  };

  return login ? (
    <AppShell>
      {(userInfo && userInfo.role === ROLES.RLSP) ?
        <Switch>
          <Route exact path={SEARCH_SHIPMENTS} component={Shipments} />
          <Route exact path={RLSP} component={rlsp} />         
          <Route exact path={SHIPMENT_DETAIL} component={ShipmentDetail} />
          <Route path="*" component={Shipments} />
          {isRTFMenuAvailable && <Route exact path={RTFCOMMERCIALINVOICE} component={RTFCommercialInvoice} />}
        </Switch>
        :
        <Fragment>
          {loginType === LOGINTYPE_RMA_EMAIL ?
            <Switch>
              <Route path={BASE} exact component={Return} />
              <Route exact path={RETURN} component={Return} />
              <Route exact path={DOCUMENTATION} component={Documentation} />
              <Route exact path={SEARCH_SHIPMENTS} component={Shipments} />
              <Route exact path={SHIPMENT_DETAIL} component={ShipmentDetail} />
              <Route path="*" component={Return} />
              {isRTFMenuAvailable && <Route exact path={RTFCOMMERCIALINVOICE} component={RTFCommercialInvoice} />}
            </Switch>
            :
            <Switch>
              <Route path={BASE} exact component={Home} />
              <Route path={DASHBOARD} component={Home} />
              <Route exact path={RETURN} component={Return} />
              <Route exact path={SEARCH_SHIPMENTS} component={Shipments} />
              <Route exact path={RLSP} component={RLSPPage} />
              <Route path={`${SEARCH_RMA}/:type?`} component={RMAs} />
              {(userInfo && userInfo.waybillAllowed) && <Route exact path={SEARCH_PREPRINTED_WAYBILL} component={PreprintedWaybill} />}
              {(userInfo && userInfo.documentAccessAllowed) && <Route exact path={DOCUMENTATION} component={Documentation} />}
              <Route exact path={SHIPMENT_DETAIL} component={ShipmentDetail} />
              <Route exact path={ADDRESS_BOOK} component={AddressBook} />
              <Route exact path={SCHEDULE} component={Schedule} />
              <Route exact path={DOWNLOAD} component={DownloadCenter} />
              <Route exact path={SETTINGS} component={Settings} />
              {isRTFMenuAvailable && <Route exact path={RTFCOMMERCIALINVOICE} component={RTFCommercialInvoice} />}
              {((userInfo.role === ROLES.ADMIN || userInfo.role === ROLES.AR_AGENT) && isreportsMenuAvalable) &&
                <>
                  <Route exact path={FR_PACKING_LIST} component={DocumentReports} />
                 <Route exact path={CH_COMMERCIAL_INVOICE} component={DocumentReports} />
                  <Route exact path={MENU_REPORTS} component={MenuReports} />
                </>
              }
              <Route path="*" component={Home} />
            </Switch>
          }
        </Fragment>
      }
    </AppShell>
  ) : <div />;
};
